#root, html{
  height: 100%;
}

@font-face {
  font-family: "Prometo";
  src: url("./content/fonts/Prometo.ttf") format("truetype");
}

@font-face {
  font-family: "soleto";
  src: url("./content/fonts/Soleto-Regular.ttf") format("truetype")
}

body {
  margin: 0;
  font-family: "Prometo", "Century Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

@page {
  /* width: 21cm;
  height: 29.7cm; */
  margin: 0cm 0cm 0cm 0cm;
}

@media print {
 #printWrapper {
    margin: 0 !important;
    width:100%;
  } 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


